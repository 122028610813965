import { AuthState } from '@aws-amplify/ui-components';
import {
  Box,
  Container,
  Flex,
  Icon,
  Link,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Button,
  Divider,
  Text,
} from '@chakra-ui/react';
import { routerActions } from 'connected-react-router';
import React, { memo, useEffect, useState } from 'react';
import { FiCalendar, FiMail, FiBookOpen, FiLogOut } from 'react-icons/fi';
import { TiArrowSortedDown } from 'react-icons/ti';
import { MdPeopleAlt } from 'react-icons/md';
import { CgProfile } from 'react-icons/cg';
import { useAppDispatch, useAppSelector } from '../hooks';
import { signOutAsync } from '../slice/userSlice';
import './shell.scss';
import { Auth } from 'aws-amplify';
import { useLocation } from 'react-router-dom';
import { IconType } from 'react-icons/lib';

const NavItem = ({ icon, title, active, onClick }: any) => {
  return (
    <Flex mt={30} flexDir="column" w="100%">
      <Menu placement="right">
        <Link
          backgroundColor={active ? '#cbe7ff' : 'inherit'}
          p={3}
          borderRadius={2}
          _hover={{ textDecor: 'none', backgroundColor: '#e8f4ff' }}
          w={'auto'}
          onClick={onClick}
        >
          <MenuButton w="100%">
            <Flex>
              <Icon as={icon} fontSize="xl" color={active ? '#82AAAD' : 'gray.500'} />
              <Text ml={5} display={'flex'}>
                {title}
              </Text>
            </Flex>
          </MenuButton>
        </Link>
      </Menu>
    </Flex>
  );
};

type NavigationItem = {
  icon: IconType;
  title: NavigationItemTitle;
  active: boolean;
  pathname: Path;
};
type NavigationItemTitle = 'レッスン登録' | 'レッスン一覧' | 'MYプロフィール' | '問い合わせ';
type Page = 'myProfile' | 'lessons' | 'registerLesson' | 'contanct';
type Path = '/lessonframes' | '/lessons' | '/profile' | '/contact';
type PageConfig = {
  [x in Page]: {
    title: NavigationItemTitle;
    pathname: Path;
  };
};
export const pageConfig: PageConfig = {
  myProfile: {
    title: 'MYプロフィール',
    pathname: '/profile',
  },
  lessons: {
    title: 'レッスン一覧',
    pathname: '/lessons',
  },
  registerLesson: {
    title: 'レッスン登録',
    pathname: '/lessonframes',
  },
  contanct: {
    title: '問い合わせ',
    pathname: '/contact',
  },
} as const;
const navs: NavigationItem[] = [
  { icon: FiCalendar, title: 'レッスン登録', active: false, pathname: '/lessonframes' },
  { icon: FiBookOpen, title: 'レッスン一覧', active: false, pathname: '/lessons' },
  { icon: CgProfile, title: 'MYプロフィール', active: false, pathname: '/profile' },
  { icon: FiMail, title: '問い合わせ', active: false, pathname: '/contact' },
];

export interface IShellProps {
  children: React.ReactNode;
}

export var orgKey = React.createContext('kokoka');
export var currentUserId = React.createContext('');

const getOrgKeyOrCurrentUserId = async (attributeName: string = 'currentUserId') => {
  const authenticatedUser = await Auth.currentAuthenticatedUser().catch((e) => null);
  if (authenticatedUser) {
    const orgKey = await authenticatedUser.attributes['custom:organizationKey'];
    const currentUserId = await authenticatedUser.attributes['sub'];
    return attributeName === 'orgKey' ? orgKey : currentUserId;
  }
  return '';
};

export const Shell: React.NamedExoticComponent<IShellProps> = memo(({ children }) => {
  const dispatch = useAppDispatch();
  const historyState = useAppSelector((state) => state.router);
  const userState = useAppSelector((state) => state.user);
  const [organizationKey, setOrganizationKey] = useState<any>('kokoka');
  const onClickBrand = () => dispatch(routerActions.push({ pathname: '/top' }));
  const onClickProfile = (close: () => void) => {
    // TODO
    close();

    dispatch(routerActions.push(pageConfig.myProfile.pathname));
  };

  const onClickSignout = async () => {
    await dispatch(signOutAsync());
  };

  const onBlur = (close: () => void) => {
    // TODO
    close();
  };

  useEffect(() => {
    getOrgKeyOrCurrentUserId().then((teacherId) => {
      currentUserId = React.createContext(teacherId);
    });
    return;
  });

  return (
    <>
      <Box
        display="flex"
        height="60px"
        borderBottom="1px solid #ddd"
        justifyContent="space-between"
        boxShadow={'sm'}
        alignItems="center"
      >
        <Box alignItems="center" display="flex" flexWrap="wrap">
          <Text
            onClick={onClickBrand}
            style={{ fontSize: '18px', fontWeight: 600, cursor: 'pointer' }}
            paddingLeft={30}
          >
            {organizationKey}
            {organizationKey == 'kokoka' ? 'にほんご' : ''}
          </Text>
          <br />
          <sub style={{ color: '#3f83bf', marginLeft: '5px', bottom: 'unset' }}>
            {organizationKey !== undefined && organizationKey.length > 0 ? ' | ' : ''}{' '}
            学習支援者管理システム
          </sub>
        </Box>
        <Box hidden={!(userState.authState === AuthState.SignedIn && userState.user)}>
          <Menu>
            <MenuButton
              as={Button}
              fontSize="12px"
              fontWeight={500}
              marginRight={3}
              height={'32px'}
              background={'white'}
              border="1px solid"
              borderColor="gray.300"
              borderRadius="4px"
              rightIcon={<TiArrowSortedDown size={13} />}
              _hover={{ background: 'white', borderColor: 'gray.500' }}
              _focus={{ background: 'white', boxShadow: 'none' }}
              _active={{ background: 'white' }}
            >
              {userState.user?.email}
            </MenuButton>
            <MenuList boxShadow="0px 4px 8px rgba(0, 0, 0, 0.2)">
              <MenuItem
                as={Button}
                fontSize="14px"
                mb={'2'}
                fontWeight={400}
                leftIcon={
                  <Icon
                    as={MdPeopleAlt}
                    boxSize="22px"
                    color="#474d66"
                    fontWeight="bold"
                    margin={'5px'}
                  />
                }
                background={'white'}
                color="#474d66"
                justifyContent="flex-start"
                borderRadius={0}
                _hover={{ background: '#f9fafc', color: '#696f8c' }}
                _focus={{ background: 'transparent', boxShadow: 'none' }}
                _active={{ background: 'transparent' }}
              >
                Profile
              </MenuItem>
              <Divider />
              <MenuItem
                as={Button}
                fontSize="14px"
                mt={'2'}
                mb={'1'}
                fontWeight={400}
                leftIcon={
                  <Icon
                    as={FiLogOut}
                    boxSize="22px"
                    color="red.500"
                    fontWeight="bold"
                    margin={'5px'}
                  />
                }
                background={'white'}
                onClick={onClickSignout}
                color="red.500"
                borderRadius={0}
                justifyContent="flex-start"
                _hover={{ background: '#f9fafc', color: 'red.500' }}
                _focus={{ background: 'transparent', boxShadow: 'none' }}
                _active={{ background: 'transparent' }}
              >
                Signout
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Box>
      <Flex>
        <Flex
          pos="sticky"
          borderRight="1px solid #ddd"
          boxShadow="sm"
          minW="200px"
          flexDir="column"
          justifyContent="space-between"
          top="0"
          h={'100vh'}
          hidden={!(userState.authState === AuthState.SignedIn && userState.user)}
        >
          <Flex
            position="sticky"
            top="0"
            p="15px"
            flexDir="column"
            w="100%"
            alignItems={'flex-start'}
            as="nav"
          >
            {navs.map((nav) => (
              <NavItem
                key={nav.title}
                icon={nav.icon}
                title={nav.title}
                active={nav.pathname === historyState.location.pathname}
                onClick={() => dispatch(routerActions.push(nav.pathname))}
              />
            ))}
          </Flex>
        </Flex>
        <Container className="Shell-children">{children}</Container>
      </Flex>
    </>
  );
});
