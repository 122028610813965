import {
  REACT_APP_API_BASE_URL,
  REACT_APP_COGNITO_REGION,
  REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  REACT_APP_COGNITO_USER_POOL_ID,
} from '../configs/env-config';
import { APIApi } from './server';
import { Amplify, Auth } from 'aws-amplify';
import { CognitoUser } from 'amazon-cognito-identity-js';
let token: any = '';
export const getAccessToken = async () => { 
  
  const user = (await Auth.currentAuthenticatedUser()) as CognitoUser;
  const token = user.getSignInUserSession()?.getAccessToken().getJwtToken() ?? ""
  return token
    
}
export const getToken = async () => {
  let token = '';
  try {
    const user = (await Auth.currentAuthenticatedUser()) as CognitoUser;
    token = user.getSignInUserSession()?.getIdToken().getJwtToken() ?? '';
  } catch (error) {
    console.log('getToken error: ', error);
  }
  return token;
};
export const serverApi = (() => {
  // FIXME Amplifyの設定を別ファイルにまとめたい。（Repositoryパターンのように使う側がAmplifyを意識しない形に。）
  Amplify.configure({
    region: REACT_APP_COGNITO_REGION,
    userPoolId: REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: REACT_APP_COGNITO_USER_POOL_CLIENT_ID,
  });
  return new APIApi({
    isJsonMime: (mime: string): boolean => {
      const jsonMime: RegExp = new RegExp(
        `^(application/json|[^;/ \t]+/[^;/ \t]+[+]json)[ \t]*(;.*)?$', 'i'`,
      );
      return (
        mime !== null &&
        (jsonMime.test(mime) || mime.toLowerCase() === 'application/json-patch+json')
      );
    },
    basePath: REACT_APP_API_BASE_URL,
    // baseOptions: axiosOptions(), → API呼び出し前にserverApi.getAxiosOptionsを実施する方法に変更
  });
})();

// 追加関数
export const requestServerApi = async <T>(callback: () => T) => {
  await serverApi.getAxiosOptions();
  return await callback();
};
